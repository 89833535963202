import type { Player as ITF1Player } from '@etf1-interne/player'
import type { SlotInfo, SpotInfo, SlotType } from '@etf1-interne/player'
import type { Modify } from '../types'
import type { IVideosData } from '../types/tms'

import { useEffect, useRef, useCallback, useContext, useMemo } from 'react'
import { TagCommanderContext } from '../contexts/tagcommander'
import {
  AD_END,
  AD_START,
  AD_RESUME,
  AD_PAUSE,
  AD_UPDATE,
  AD_PAUSE_DISPLAY,
  SLOT_START,
  SLOT_END,
  ROLL_END,
  TMS_PLAY,
  TMS_PAUSE,
  TMS_END,
  TMS_ROLL_PLAY,
  TMS_SPOT_PLAY,
  TMS_SPOT_PAUSE,
  TMS_SPOT_RESUME,
  TMS_PLAYALONG_FALSE,
  TMS_PLAYALONG_AUTO,
  TMS_PLAYALONG_MANUAL,
  TMS_SPOT_END,
  TMS_ACTION_END,
} from '../constants/playerEvents'

export interface IEvent {
  ad_adType: SlotType
  ad_end: boolean
  ad_spotPosition: number
  ad_spotPositionAdType: number
  ad_spotPositionGlobal: number
  ad_spotsAmount: number
  ad_spotsAmountAdType: number
  ad_spotsAmountGlobal: number
  content_video_action: typeof TMS_PLAY | typeof TMS_PAUSE | typeof TMS_END
  content_video_duration: string
  content_video_playAlong: string
  content_video_videoEmNumber: string
  content_video_videoFormat: string
  content_video_videoName: string
  content_video_videoRefvideoId: string
  content_video_videoType: string
  player_playAuto: string
  player_playerPosition: string
  player_playSoundOn: string
  program_attributedEditor: string
  program_broadcastingChannel: string
  program_programNameMove: string
  program_programIdMove: string
  program_programName: string
  program_linkingChannel: string
}
export type IEventParser = Modify<
  IEvent,
  {
    ad_end: string
    ad_spotPosition: string
    ad_spotPositionAdType: string
    ad_spotPositionGlobal: string
    ad_spotsAmount: string
    ad_spotsAmountAdType: string
    ad_spotsAmountGlobal: string
  }
>

interface IEventTrackerPlayer {
  player: { current: ITF1Player }
  isPlayerInitialized: boolean
  videoId?: string | number
  videosData: IVideosData
  tms: {
    playAlongTms:
      | typeof TMS_PLAYALONG_FALSE
      | typeof TMS_PLAYALONG_AUTO
      | typeof TMS_PLAYALONG_MANUAL
    startsWithAutoplay: {
      current: boolean
    }
    unMute: {
      current: boolean
    }
    getPlayerPosition: () => number | undefined
  }
}

export const AdsPlayerListeners = {
  [AD_END]: 'handleAdEnd',
  [AD_START]: 'handleAdStart',
  [AD_PAUSE]: 'handleAdPause',
  [AD_RESUME]: 'handleAdResume',
  [AD_UPDATE]: 'handleUpdate',
  [AD_PAUSE_DISPLAY]: 'handleAdPauseDisplay',
  [SLOT_START]: 'handleSlotStart',
  [SLOT_END]: 'handleSlotEnd',
}

/**
 *
 * @description This hook is used to track all the events related to the ads in the player
 */
export function usePlayerAdsTMSHits({
  player,
  isPlayerInitialized,
  videoId,
  videosData,
  tms,
}: IEventTrackerPlayer) {
  const { isTagCommanderReady, hit } = useContext(TagCommanderContext)

  const event = useRef<IEvent>({
    ad_adType: '',
    ad_end: true,
    ad_spotPosition: 1,
    ad_spotPositionAdType: 1,
    ad_spotPositionGlobal: 0,
    ad_spotsAmount: 1,
    ad_spotsAmountAdType: 1,
    ad_spotsAmountGlobal: 1,
    content_video_action: TMS_PLAY,
    content_video_duration: '0',
    content_video_playAlong: TMS_PLAYALONG_FALSE,
    content_video_videoEmNumber: '',
    content_video_videoFormat: '',
    content_video_videoName: '',
    content_video_videoRefvideoId: videoId ? String(videoId) : '',
    content_video_videoType: '',
    player_playAuto: String(tms.startsWithAutoplay.current),
    player_playerPosition: String(tms.getPlayerPosition() || ''),
    player_playSoundOn: String(tms.unMute.current),
    program_attributedEditor: '',
    program_broadcastingChannel: '',
    program_programNameMove: '',
    program_programIdMove: '',
    program_linkingChannel: '',
    program_programName: '',
  })

  const setEvent = (spot) => {
    const type: SlotType = spot?.contentType?.toLowerCase() || spot?.type?.toLowerCase() || null
    const { ad_spotPositionGlobal } = event.current
    event.current = {
      ...event.current,
      ad_adType: type || event.current.ad_adType,
      ad_spotPosition: spot?.spotPosition || event.current.ad_spotPosition,
      ad_spotPositionAdType: spot?.currentAdIndex || event.current.ad_spotPositionAdType,
      ad_spotsAmountAdType:
        spot?.adsCount || spot?.spotsAmount || event.current.ad_spotsAmountAdType,
      ad_spotsAmount: spot?.adsCount || spot?.spotsAmount || event.current.ad_spotsAmount,
      ad_spotsAmountGlobal:
        spot?.spotsAmountGlobal ||
        (spot?.type && spot?.adsCount) ||
        event.current.ad_spotsAmountGlobal,
      ad_spotPositionGlobal:
        (spot?.currentAdIndex && ad_spotPositionGlobal) + 1 || ad_spotPositionGlobal,
      ad_end: spot.ad_end === false || spot.ad_end === true ? spot.ad_end : event.current.ad_end,
      content_video_action: spot.content_video_action || event.current.content_video_action,
    }
  }

  const parseEvent = ({
    ad_spotPosition,
    ad_spotPositionAdType,
    ad_spotsAmountAdType,
    ad_spotsAmount,
    ad_spotsAmountGlobal,
    ad_spotPositionGlobal,
    ad_end,
    ...event
  }: IEvent): IEventParser => {
    return {
      ...event,
      player_playAuto: String(tms.startsWithAutoplay.current),
      player_playSoundOn: String(tms.unMute.current),
      ad_spotPosition: String(ad_spotPosition),
      ad_spotPositionAdType: String(ad_spotPositionAdType),
      ad_spotsAmountAdType: String(ad_spotsAmountAdType),
      ad_spotsAmount: String(ad_spotsAmount),
      ad_spotsAmountGlobal: String(ad_spotsAmountGlobal),
      ad_spotPositionGlobal: String(ad_spotPositionGlobal),
      ad_end: String(ad_end),
      player_playerPosition: String(tms.getPlayerPosition() || ''),
    }
  }

  // Define all Ads listeners for the player
  const listeners = useMemo(
    () => ({
      handleSlotStart: (slot: SlotInfo) => {
        if (isTagCommanderReady && slot?.value) {
          setEvent({ ...slot.value, content_video_action: TMS_PLAY, ad_end: false })
          const {
            ad_adType,
            ad_spotsAmount,
            ad_spotsAmountAdType,
            ad_spotsAmountGlobal,
            content_video_action,
            content_video_duration,
            content_video_playAlong,
            content_video_videoEmNumber,
            content_video_videoFormat,
            content_video_videoName,
            content_video_videoRefvideoId,
            content_video_videoType,
            program_attributedEditor,
            program_broadcastingChannel,
            program_programNameMove,
            program_programIdMove,
            ad_end,
          } = event.current
          // Delaying the tms roll play event because such event sent too fast
          // could cause trouble during analysis process
          // Details : https://e-tf1.atlassian.net/browse/LCI-8126
          hit(
            {
              id: TMS_ROLL_PLAY,
              ad_adType,
              ad_spotsAmount: String(ad_spotsAmount),
              ad_spotsAmountAdType: String(ad_spotsAmountAdType),
              ad_spotsAmountGlobal: String(ad_spotsAmountGlobal),
              content_video_action,
              content_video_duration,
              content_video_playAlong,
              content_video_videoEmNumber,
              content_video_videoFormat,
              content_video_videoName,
              content_video_videoRefvideoId,
              content_video_videoType,
              player_playAuto: String(tms.startsWithAutoplay.current),
              player_playSoundOn: String(tms.unMute.current),
              program_attributedEditor,
              program_broadcastingChannel,
              program_programNameMove,
              program_programIdMove,
              ad_end: String(ad_end),
            },
            { delay: 200 },
          )
        }
      },
      handleSlotEnd: (slot: SlotInfo) => {
        if (isTagCommanderReady && slot?.value) {
          setEvent({ ...slot.value, content_video_action: TMS_END, ad_end: true })

          hit({
            id: ROLL_END,
            ...parseEvent(event.current),
          })
        }
      },
      handleAdStart: (spot: SpotInfo) => {
        if (isTagCommanderReady && spot?.value) {
          setEvent({ ...spot.value, content_video_action: TMS_PLAY, ad_end: false })
          const parsedEvent = parseEvent(event.current)
          hit(
            {
              id: TMS_SPOT_PLAY,
              ...parsedEvent,
            },
            {
              delay: parsedEvent.content_video_playAlong !== 'false' ? 800 : null,
            },
          )
        }
      },
      handleAdPause: () => {
        if (isTagCommanderReady) {
          setEvent({ content_video_action: TMS_PAUSE, ad_end: false })
          hit({
            id: TMS_SPOT_PAUSE,
            ...parseEvent(event.current),
          })
        }
      },
      handleAdResume: () => {
        if (isTagCommanderReady) {
          setEvent({ content_video_action: TMS_PLAY, ad_end: false })
          hit({
            id: TMS_SPOT_RESUME,
            ...parseEvent(event.current),
          })
        }
      },
      handleAdEnd: (spot: SpotInfo) => {
        if (isTagCommanderReady && spot?.value) {
          setEvent({ ...spot.value, content_video_action: TMS_ACTION_END })
          const parsedEvent = parseEvent(event.current)
          hit({
            id: TMS_SPOT_END,
            ...parsedEvent,
          })
        }
      },
    }),
    [isTagCommanderReady, hit],
  )

  // Link all listeners to the player with the corresponding event
  const setupListeners = useCallback(() => {
    Object.keys(AdsPlayerListeners)?.forEach((listener) => {
      if (listeners[AdsPlayerListeners[listener]]) {
        player.current?.on?.(listener, listeners[AdsPlayerListeners[listener]])
      }
    })
  }, [listeners])

  const destroyListeners = useCallback(() => {
    Object.keys(AdsPlayerListeners)?.forEach((listener) => {
      if (listeners[AdsPlayerListeners[listener]]) {
        player.current?.off?.(listener, listeners[AdsPlayerListeners[listener]])
      }
    })
  }, [listeners])

  // Setup listeners when player is initialized
  useEffect(() => {
    if (isPlayerInitialized) setupListeners()
    return () => {
      if (isPlayerInitialized) destroyListeners()
    }
  }, [isPlayerInitialized, setupListeners, destroyListeners])

  // Update event data when videoId changes
  useEffect(() => {
    if (videosData[videoId]) {
      const {
        content_video_duration,
        content_video_videoEmNumber,
        content_video_videoFormat,
        content_video_videoRefvideoId,
        content_video_videoType,
        program_attributedEditor,
        program_broadcastingChannel,
        program_programNameMove,
        program_programIdMove,
      } = videosData[videoId]

      event.current = {
        ...event.current,
        player_playAuto: String(tms.startsWithAutoplay.current),
        player_playSoundOn: String(tms.unMute.current),
        content_video_duration: String(content_video_duration),
        content_video_playAlong: String(tms.playAlongTms),
        content_video_videoEmNumber: String(content_video_videoEmNumber),
        content_video_videoFormat: String(content_video_videoFormat),
        content_video_videoRefvideoId: String(content_video_videoRefvideoId),
        content_video_videoType: String(content_video_videoType),
        program_attributedEditor: String(program_attributedEditor),
        program_broadcastingChannel: String(program_broadcastingChannel),
        program_programNameMove,
        program_programIdMove,
      }
    }
  }, [videosData, videoId])
}
